import React from 'react';
import PropTypes from 'prop-types';
import {useMediaQuery} from 'react-responsive';
import FollowUs from './FollowUs';
import ContactUs from './ContactUs';
import InternationalSites from 'components/internationalSites';
import FooterLinks from './FooterLinks';
import FooterPayment from './FooterPayment';
import FooterSeo from './FooterSeo';
import Copyright from './Copyright';
import EventTrigger from 'helpers/EventTrigger';
import {isEmpty,flatMapDeep} from 'lodash/fp';
import {TABLET_WIDTH} from 'helpers/stableValues';

function renderFooterLinks(data, isTablet) {
  return !isEmpty(flatMapDeep('values', data)) && <FooterLinks isTablet={isTablet} links={data} />;
}

function renderSitesSocial({internationalSites, followUs}) {
  return <div className="grid-item-lg-4-10 grid-item-md text-center text-md-right">
    {
      internationalSites.isEnabled &&
      <InternationalSites store={internationalSites} />
    }
    {
      followUs.isEnabled &&
      <FollowUs {...followUs}/>
    }
  </div>;
}

renderSitesSocial.propTypes = {
  internationalSites: PropTypes.object,
  followUs: PropTypes.object
};

const Footer = ({store}) => {
  const {internationalSites: {isEnabled: sitesIsEnabled}, followUs: {isEnabled: iconsIsEnabled},
    contactCms, links, payment, isAirtickets, isFerryscanner, isAffiliate, seo, copyright} = store;

  const isTablet = useMediaQuery({query: `(max-width: ${TABLET_WIDTH}px)`});

  return (
    <footer id="site-footer">
      <div className="bg-dark py-4">
        <div className="container">
          <div className="grid">
            {
              !isEmpty(contactCms) &&
                <ContactUs {...contactCms} isFerryscanner={isFerryscanner} isAffiliate={isAffiliate} />
            }
            {renderFooterLinks(links, isTablet)}
          </div>
        </div>
      </div>
      {!isAffiliate && <div className="bg-light py-4 text-muted text-small">
        <div className="container">
          <FooterPayment {...payment}/>
          {!isAirtickets &&
            <><hr/>
              <div className="grid">
                <div className="grid-item-lg-6-10"><FooterSeo {...seo}/></div>
                {(sitesIsEnabled || iconsIsEnabled) && renderSitesSocial(store)}
              </div>
            </>
          }
          <hr/>
          <Copyright {...copyright}/>
        </div>
      </div>}
      <EventTrigger id="footer-rendered" event="footerRendered"/>
    </footer>
  );
};

Footer.propTypes = {
  store: PropTypes.shape({
    followUs: PropTypes.object,
    signUp: PropTypes.object,
    internationalSites: PropTypes.object,
    payment: PropTypes.object,
    copyright: PropTypes.object,
    links: PropTypes.array.isRequired,
    contactCms: PropTypes.object.isRequired,
    seo: PropTypes.object.isRequired,
    isAirtickets: PropTypes.bool.isRequired,
    isFerryscanner: PropTypes.bool.isRequired,
    isAffiliate: PropTypes.oneOfType([
      PropTypes.object,
      PropTypes.bool
    ])
  })
};

export default Footer;

